import React from "react"
import TwentyYearsStrong from "../HomePage/TwentyYearsStrong"
import CtaButton from "../Buttons/CtaButton"
import DonateButton from "../Buttons/DonateButton"

import { Box, Center, Flex, Text, Heading, Container } from "@chakra-ui/react"

const PageHero = props => {
  return (
    <>
      <Box
        mb={{ base: "-9px", md: "-15px" }}
        ml="10px"
        maxW={{ sm: "320px", md: "600px" }}
      >
        <TwentyYearsStrong width="100%" />
      </Box>
      <Box backgroundColor="rgba(255, 255, 255, 0.8)" marginBottom="4">
        <Box textAlign="right" padding="2">
        </Box>
        <Box padding="16px">
          <Box>
            <Heading
              as="h1"
              color="red.400"
              textAlign="center"
              marginBottom="4"
              fontSize={{ sm: "1.5rem", md: "1.5rem" }}
            >
              {props.title}
            </Heading>
          </Box>
          {/* <Box>
            <Flex h="100%" flexGrow="1" justifyContent="center">
              <Box w={{sm: '100%', md: '50%'}}>
              <GatsbyImage image={getImage(props.featuredImage)} alt="" />
              </Box>
            </Flex>
          </Box> */}
          <Box>
            <Container maxW="container.sm">
            <Text
              color="blue.700"
              textAlign="center"
              fontSize={{ base: "1rem", md: "1.25rem " }}
            >
              {props.description}
            </Text>
            </Container>
          </Box>
          {/* <Box>
            <Text
              color="blue.700"
              textAlign="center"
              fontSize={{ base: "1rem", md: "1.25rem " }}
            >
              <RichText render={props.description} serializeHyperlink={CustomLink} />
            </Text>
          </Box> */}
          <Center>
            <Flex
              mt="2"
              width={{ md: "100%" }}
              justifyContent={{ sm: "center", md: "center", lg: "center" }}
              flexWrap="wrap"
            >
              { props.buttons && (
                <>
                  {!props.buttons[0]?.cta_button_label.text == ""
                    ? props.buttons.map(ctaButton => {
                        return (
                          <Box m="2">
                            { ctaButton.cta_button_label.text === "Donate" ? (
                              <DonateButton 
                                colorScheme={ctaButton.button_color}
                                btnText={ctaButton.cta_button_label.text}
                              />
                            ) : (
                              <CtaButton
                              colorScheme={ctaButton.button_color}
                              btnText={ctaButton.cta_button_label.text}
                              linkTo={ctaButton.cta_button_link.url}
                            />
                            )
                            }
                            
                          </Box>
                        )
                      })
                    : null}{" "}
                </>
              )}
            </Flex>
          </Center>
        </Box>
      </Box>
    </>
  )
}

export default PageHero