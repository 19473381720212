import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'
import styled from '@emotion/styled'

const BackgroundSection = (props) => {

  // Explicitly delcare props and destructure the other properties:
  const { className, children, ...rest } = props;
  
  const data = useStaticQuery(graphql`
    {
      allFile(filter: {relativePath: {eq: "tetons-our-work.jpg"}}) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: AUTO)
            }
          }
        }
      }
    }
  `)

  const fallbackImage = getImage(data.allFile.edges[0].node.childImageSharp)
  const image = getImage(props.backgroundImage)

  return (
    <div style={{width: '100%', height: '100vh'}}>
      {/* Image with fallback */}
      {props.backgroundImage ? (
        <BgImage image={image} alt={"Background image"} 
            style={{
              width: '100%',
              backgroundPosition: 'top center',
              backgroundAttachment: 'fixed',
              backgroundSize: 'cover'
            }}>
          <div>{children}</div>
        </BgImage>
      ) : 
      (
        <BgImage image={fallbackImage} alt={"Background image"} 
            style={{
              width: '100%',
              backgroundPosition: 'top center',
              backgroundAttachment: 'fixed',
              backgroundSize: 'cover'
            }}>
            <div>{children}</div>
          </BgImage>
      )}
    
    </div>
  )
}

export default BackgroundSection